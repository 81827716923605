/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "Westmeath";
    src: url("/assets/fonts/Westmeath.otf");
}

@font-face {
    font-family: "Tw Cen Mt";
    src: url("/assets/fonts/TW_CEN_MT.TTF");
}

@font-face {
    font-family: "Singlet Regular";
    src: url("/assets/fonts/Sniglet-Regular.ttf");
}

@font-face {
    font-family: "TCCB";
    src: url("/assets/fonts/TCCB____.TTF");
}

@font-face {
    font-family: "Chinacat";
    src: url("/assets/fonts/chinrg_.ttf");
}

@font-face {
    font-family: "Baloo_bold";
    src: url("/assets/fonts/Baloo_bold.ttf");
}


/* Modal */
.ui-dialog>.ui-widget-header {
    border: none;
    background: transparent;
    background-size: 100%;
}

.ui-dialog .ui-dialog-title {
    text-align: center;
    width: 100%;
}

.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close{
    background-color: brown;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
}
